.alert {
  @container (width < 10em) {
    display: none;
  }
  &[data-type='info'] {
    background-color: var(--mantine-color-blue-1);
    .alertTitle {
      color: var(--mantine-color-blue-8);
    }
    .alertIcon {
      color: var(--mantine-color-blue-7);
    }
  }
  &[data-type='error'] {
    background-color: var(--mantine-color-red-1);
    .alertTitle {
      color: var(--mantine-color-red-8);
    }
    .alertIcon {
      color: var(--mantine-color-red-7);
    }
  }
  &[data-type='warning'] {
    background-color: var(--mantine-color-yellow-1);
    .alertTitle {
      color: var(--mantine-color-yellow-8);
    }
    .alertIcon {
      color: var(--mantine-color-yellow-7);
    }
  }
  &[data-type='success'] {
    background-color: var(--mantine-color-brand-1);
    .alertTitle {
      color: var(--mantine-color-brand-8);
    }
    .alertIcon {
      color: var(--mantine-color-brand-7);
    }
  }

  a {
    text-decoration: underline;
  }
}

.alertTitle {
  font-size: var(--mantine-font-size-lg);
}

.alertMessage {
  font-size: var(--mantine-font-size-md);

  p {
    margin-bottom: 1em;
  }
}
